<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12 class="pa-3 pb-0">
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal column title-item">
          <v-avatar
            @click="$router.go(-1)"
            size="36"
            style="cursor: pointer;"
            color="#555"
            icon
          >
            <v-icon color="#000" size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center; height: 40px;"
        >
          <v-icon color="#666" size="38" style="padding-bottom: 3px;"
            >mdi-cash-fast</v-icon
          >
          <span style="font-size: 19pt; text-wrap: nowrap;" class="fonte-bold">
            Nova transferência
          </span>
        </div>
      </div>
    </v-flex>
    <v-window touchless v-model="step">
      <v-window-item value="1">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                1. Informe o cpf do destinatário
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                v-mask="['###.###.###-##']"
                prepend-inner-icon="mdi-account-circle-outline"
                large
                v-model="cpf"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal centraliza">
                <v-btn
                  @click="getDestUser"
                  :loading="loading"
                  x-large
                  color="#555"
                  dark
                  rounded
                  >Buscar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="2">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex xs12>
              <v-list class="fonte" color="transparent">
                <v-list-item class="px-0">
                  <v-avatar color="#555" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Destino
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-avatar color="#555" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-cash</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $helper.formataSaldo(balance.balance) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Saldo disponível
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                2. Valor a enviar
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                prefix="$"
                v-mask="[
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '##.###,##',
                  '###.###,##'
                ]"
                large
                v-model="transfer_value"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal column centraliza">
                <v-btn
                  @click="transfer"
                  :loading="loading"
                  x-large
                  color="#555"
                  dark
                  rounded
                  >Pagar</v-btn
                >
                <v-btn
                  class="mt-3"
                  @click="step = '1'"
                  :loading="loading"
                  small
                  color="grey"
                  dark
                  text
                  >Cancelar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
    </v-window>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["transaction_type"],
  data() {
    return {
      user: {},
      balance: {},
      step: "1",
      cpf: "",
      transfer_value: "",
      loading: false
    };
  },
  methods: {
    ...mapActions(["getDestinationUser", "transferBalance"]),
    getDestUser() {
      this.loading = true;
      this.getDestinationUser({
        cpf: this.cpf,
        transaction_type: this.transaction_type
      }).then(res => {
        this.loading = false;
        this.user = res.user;
        this.balance = res.balance;
        this.step = "2";
      });
    },
    transfer() {
      if (parseFloat(this.transfer_value) < 1) {
        alert("O valor deve ser 1 real ou maior");
        return;
      }
      this.transferBalance({
        value: this.transfer_value,
        user: this.user,
        transaction_type: this.transaction_type
      }).then(() => {
        this.$router.push("/dashboard");
      });
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  }
};
</script>
